@font-face {
  font-family: "Axis";
  src: local("Axis"), url("./fonts/axis.otf");
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax"), url("./fonts/colfax.woff2");
}

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/table/lib/css/table.css";

:root {
  --main-panel-background: #26292c;
  --main-panel-transparency: rgba(38, 41, 44, 0.1);
  --active-text-default-bg: #e6e6e6;
  --inactive-text-default-bg: #939496;
  --main-txt-color: blue;
  --main-padding: 15px;
  --calendar-border: #555b64;
  --side-panel-background: #17191a;
  --vermillion: #96290d;
  --rose: #a82255;
  --violet: #5c255c;
  --indigo: #5642a6;
  --cerulean: #0c5174;
  --turquoise: #004d46;
  --forest: #1d7324;
  --lime: #43501b;
  --gold: #5c4405;
  --sepia: #5e4123;
  --green: #165a36;
  --indigo5: #ad99ff;
  --violet5: #d69fd6;
  --cerulean5: #68c1ee;
  --violet3: #9d3f9d;
  --gray5: #404854;
}

/*** CLINICAL V2 *****/

.pintura-editor {
  --color-background: 0, 0, 0 !important;
  --color-foreground: 255, 255, 255 !important;
}
.flash {
  animation: flash-animation 500ms ease-in-out;
}

@keyframes flash-animation {
  0% {
    background-color: var(--cerulean);
  }
  100% {
    background-color: transparent;
  }
}

.strike_through {
  text-decoration: line-through;
  color: var(--inactive-text-default-bg);
}
.small_text {
  font-size: small;
  font-family: "Colfax" !important;
}
.narrow_cell {
  padding: 3px !important;
  text-align: center !important;
  vertical-align: middle !important;
}
.big_input {
  width: 30vh !important;
}
.table_vertical_align_center {
  vertical-align: middle;
}

.table_vertical_align_center td,
.table_vertical_align_center th {
  vertical-align: middle;
}

.small_input {
  width: 180px !important;
}
.first_cell {
  padding-left: 0px !important;
}
.card__container {
  padding: 5px;
  margin-bottom: 15px;
}
.flex_box_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.flex__push__apart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.scroll_individual {
  height: 100vh;
  overflow: auto !important;
}

.no__bg {
  background-color: transparent !important;
}
.sepia__bg {
  background-color: var(--sepia) !important;
}
.gray5__bg {
  background-color: var(--gray5) !important;
}
.violet__bg {
  background-color: var(--violet) !important;
}

.violet3__bg {
  background-color: var(--violet3) !important;
}

.cerulean5__bg {
  background-color: var(--cerulean5) !important;
}
.rose__bg {
  background-color: var(--rose) !important;
}
.forest__bg {
  background-color: var(--forest) !important;
}
.vermillion__bg {
  background-color: var(--vermillion) !important;
}
.green__bg {
  background-color: var(--green) !important;
}
.cerulean__bg {
  background-color: var(--cerulean) !important;
}
.turquoise__bg {
  background-color: var(--turquoise) !important;
}
.indigo__bg {
  background-color: var(--indigo) !important;
}

.ai__messages {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px;
  text-align: left;
  font-size: small;
  background-color: var(--side-panel-background) !important;
}
.indigo5__bg {
  background-color: var(--indigo5) !important;
}
.lime__bg {
  background-color: var(--lime) !important;
}
.cerulean__bg {
  background-color: var(--cerulean) !important;
}
.gold__bg {
  background-color: var(--gold) !important;
}
.vision_right_border {
  border-right: 1px solid var(--inactive-text-default-bg) !important;
}

.vision_left_border {
  border-left: 1px solid var(--inactive-text-default-bg) !important;
}
.spaced_letters {
  letter-spacing: 1.05ch !important;
  text-transform: uppercase !important;
}
.colfax {
  font-family: "Colfax" !important;
  text-align: start !important;
}

.padding_10 {
  padding: 10px;
}
.clinical_header {
  padding: 10px;
  margin-bottom: 10px;
}

.lime {
  background-color: #d4f17e !important;
}
.forest {
  background-color: #62d96b !important;
}
.cerulean {
  background-color: #68c1ee !important;
}
.turqoise {
  background-color: #7ae1d8 !important;
}
.indigo {
  background-color: #bdadff !important;
}
.vermillion {
  background-color: #ff9980 !important;
}
.lime_dark {
  background-color: #43501b !important;
}
.forest_dark {
  background-color: #1d7324 !important;
}
.cerulean_dark {
  background-color: #0c5174 !important;
}
.turqoise_dark {
  background-color: #004d46 !important;
}
.indigo_dark {
  background-color: #5642a6 !important;
}
.vermillion_dark {
  background-color: #96290d !important;
}
.rose_dark {
  background-color: #a82255 !important;
}

.ai_message_container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
}

.user_message_container {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 10px;
}

.chat_bubble {
  margin-top: 3px;
  padding: 10px;
  word-wrap: break-word;
  font-family: "Colfax" !important;
  max-width: 85%;
  border-radius: 3px 3px 10px 10px;
}

.user_message {
  font-size: small;
  background-color: #004d46;
}

.ai_message {
  font-size: small;
  background-color: #0c5174;
}
.ai_message_image {
  font-size: small;
  background-color: none;
}
/*** CLINICAL V2 *****/

.invisible {
  visibility: hidden;
}
.focus-input {
  width: 150px;
  -webkit-transition: width 0.35s ease-in-out;
  transition: width 0.35s ease-in-out;
}
.focus-input:focus-within {
  width: 300px;
}
.App {
  text-align: center;
  font-family: "Axis";
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.App::-webkit-scrollbar {
  display: none;
}

.axis {
  font-family: "Axis" !important;
}

.focus-change:focus {
  background-color: #17191a !important;
}
.focus-change:focus-within {
  background-color: #17191a !important;
  /*  border: aliceblue 1px solid; */
}

.full-width {
  width: 100% !important;
}
.zero-pad {
  margin-right: 2px !important;
  padding-right: 1px !important;
}
.bbb {
  background-color: yellow !important;
}

.td,
.th {
  text-align: center !important;
}

.ag-default-dark,
.ag-material-dark,
.ag-pastel-dark,
.ag-vivid-dark,
.ag-solar-dark {
  font-family: "Axis" !important;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #17191a !important;
}
.filepond--drop-label {
  color: #a9a9ab !important;
}
pintura-editor {
  --color-background: 255, 255, 255 !important;
  --color-foreground: 0, 0, 0 !important;
}
.grad {
  background: linear-gradient(to right, #17191a, #363a40);
}

/** Search Bar */

.searchbar-outer {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 10%;
  padding-bottom: 3%;
}

.searchbar-title {
  font-size: xx-large;
  color: #a9a9ab;
  padding-bottom: 2%;
  text-transform: uppercase;
  letter-spacing: 12px;
}
.searchbar-help {
  color: #e6e6e6;
  padding-top: 0.5%;
}

.main {
  padding-top: 50px !important;
  position: fixed;

  outline: none;
  width: 100%;
  height: 100%;

  background: linear-gradient(to right, #17191a, #363a40);
  overflow-y: auto;
  font-size: medium;
}

.searchresults-outer {
  margin: auto;
  width: 60%;
  margin-bottom: 10%;
  outline: none;
  height: 50%;
  overflow-y: auto;
}

.searchresult-patientname {
  font-size: large;
  float: left;
  color: #eaeaea;
}

.searchresult-patientphone {
  font-size: large;
  float: right;
  color: #eaeaea;
}

.searchresult-patientid {
  padding-left: 2em;
  font-size: large;
  float: left;
  color: #cdced0;
}

.searchresult-patientinfo {
  padding-left: 2em;
  font-size: large;
  float: left;
  color: #cdced0;
  max-width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.addnewpatientbutton-outer {
  padding-left: 25%;
  padding-right: 25%;
  color: #eaeaea;
  font-size: large;
}

.single-panel {
  padding-top: 1%;
  width: 40%;
  min-width: 500px;
  margin: auto;
  margin-bottom: 10%;
  outline: none;
  overflow-y: auto;
  height: 90vh;
}

.calendar-outer {
  padding-top: 3%;
  width: 95%;
  min-width: 30%;
  margin: auto;
  margin-bottom: 10%;
  outline: none;

  overflow-y: auto;
}

/* .bp3-dark .bp3-datepicker {
  background: #464b53;
} */

.symptomselector {
  padding-top: 10px;
  padding-bottom: 10px;
}

.booking-patient-section {
  padding-top: 1%;
  max-width: 80%;
  min-width: 30%;
  margin: auto;
  margin-bottom: 10%;
  outline: none;
}

.doctorcard-patientname {
  font-size: large;
  float: left;
  color: #eaeaea;
}

.doctorcard-patientphone {
  font-size: large;
  float: right;
  color: #eaeaea;
}

.doctorcard-patientid {
  padding-left: 2em;
  font-size: large;
  float: left;
  color: #cdced0;
}

.doctorcard-patientinfo {
  padding-left: 2em;
  font-size: medium;
  float: left;
  color: #cdced0;
  max-width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.doctor-results {
  background-color: bisque;
  outline: blue;
}

.calendar {
  color: var(--active-text-default-bg);
}

.dialog {
  font-family: "Axis";
}
.event-dialog {
  padding: 20px;
  background-color: #464b53;
}

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 1px;
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  color: var(--main-panel-background);
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected {
  background-color: #fd6363;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: none;
}
.rbc-header + .rbc-header {
  border-left: 1px solid var(--calendar-border);
}

.rbc-today {
  background-color: #464b53;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: var(--active-text-default-bg);
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: none;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: var(--inactive-text-default-bg);
  border-color: none;
}

.rbc-month-view {
  position: relative;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.rbc-month-row + .rbc-month-row {
  border-top: 0.5px solid var(--calendar-border);
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 0.5px solid var(--calendar-border);
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: none;
  background-color: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.rbc-overlay-header {
  border-bottom: none;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-off-range {
  color: none;
}

.rbc-off-range-bg {
  background: none;
}

.rbc-timeslot-group {
  border-bottom: 0.5px solid var(--calendar-border);
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-day-slot .rbc-event {
  border: none;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 0.5px solid var(--calendar-border);
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: none;
  min-height: 0;
}
.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.rbc-time-header > .rbc-row:first-child {
  border-bottom: none;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: none;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: none;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid var(--calendar-border);
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 0.4px solid var(--calendar-border);
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--active-text-default-bg);
  pointer-events: none;
}

.refundreason {
  padding: 10px;
}

.sendorprint {
  padding: 20px;
}

.navbar-heading {
  text-transform: uppercase;
  letter-spacing: 12px;
  line-height: 50px;
  color: var(--active-text-default-bg);
  float: left;
  padding-left: 1%;
}

.navbar {
  height: 50px;
  box-shadow: 0 6px 6px -6px black !important;
  background-color: var(--main-panel-background) !important;
}

.navbar-button {
  height: 50px;
  float: right;
}

.ct-toast {
  color: var(--active-text-default-bg) !important;
  background: var(--main-panel-transparency) !important;
  font-family: "Axis" !important;
}
.ct-toast:hover {
  color: var(--active-text-default-bg) !important;
  background: var(--main-panel-background) !important;
}

.landing {
  height: 100vh;
  width: 100%;
  background: linear-gradient(to right, #17191a, #363a40);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.td {
  vertical-align: middle;
}

.footer {
  color: #a9a9ab;
  padding-bottom: 2%;
  text-transform: uppercase;
  letter-spacing: 12px;
  bottom: 1%;
  position: absolute;
}

.large-left-panel {
  height: 100%;
  background-color: var(--main-panel-background);
  overflow-y: scroll;
  font-size: medium;
}

/* .large-left-panel {
  position: fixed;
  outline: none;
  width: 75%;
  height: 100%;
  background-color: var(--main-panel-background);
  overflow-y: auto;
  font-size: medium;
} */

.small-right-panel {
  background-color: var(--side-panel-background);
  height: 100%;
  overflow-y: scroll;
}
/* 
.small-right-panel {
  right: 0;
  position: fixed;
  width: 25%;
  height: 100%;
  background-color: var(--side-panel-background);
  overflow-y: auto;
  overflow-x: scroll;
  outline: none;
} */

.thread-unselected {
  width: 100%;
  height: 50px;
  background-color: #17191a;
  color: aliceblue;
  overflow-y: auto;
  line-height: 50px;
  padding-left: 2%;
  text-align: left;
  font-size: 80%;
  border-left: 5px solid #17191a;
  display: flex;
  align-items: center;
}

.thread-unselected:focus {
  background-color: #464b53;
  outline: none;
  border-left: 5px solid burlywood;
}
.thread-unselected:hover {
  background-color: #252a31 !important;
  outline: none;
  /* border-left: 5px solid burlywood; */
}

.thread-patient-name {
  color: var(--active-text-default-bg);
  float: left;
  width: 15%;
  text-transform: capitalize;
}

.thread-patient-clinic {
  color: var(--active-text-default-bg);
  float: left;
  width: fit-content;
  padding-right: 1%;
}

.thread-patient-summary {
  color: var(--inactive-text-default-bg);

  float: left;
  max-width: 55%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.thread-patient-id {
  color: var(--inactive-text-default-bg);
  float: right;
  width: 15%;
  text-transform: uppercase;
}

.booking-view-panel {
  color: var(--inactive-text-default-bg);
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.booking-view-large {
  font-size: larger;
  color: var(--active-text-default-bg);
}

.three-panel {
  padding-top: 1%;
  width: 100vw;
  margin-bottom: 5%;
  outline: none;
  overflow-y: auto;
  height: 90vh;
}

.three-panel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.magic-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.react_time_range__rail__inner {
  background-color: #17191a !important;
}

.centre-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DayPicker-Day--selected-range {
  background-color: var(--main-panel-background) !important;
}
.centre-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bp3-popover-content {
  background-color: #26292c !important;
  color: #939496 !important;
}

.spaced-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* the resizer for the SplitPanes*/
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
/* .Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
} */
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
